import React, { useRef, useEffect } from "react"
import Layout from "../components/layout"

import * as d3 from 'd3';

import Card from "../components/card"

import FitnessData from "../../data/fitness"

console.log(FitnessData)

function d3render(container) {
  const svg = d3.select(container.current)
    .append(`svg`)
    .attr(`width`, `100%`)
    .attr(`height`, `50vh`)

  const x = d3.scaleTime()
    .domain(d3.extent(FitnessData, d=>d.date))
    .range([0,500])
  svg.append('g')
    .attr('transform', "translate(30,330)")
    .call(d3.axisBottom(x))

  const y = d3.scaleLinear()
    .domain([0,100])
    .range([300,0])
  svg.append('g')
    .attr('transform', "translate(30,30)")
    .call(d3.axisLeft(y))

  svg.append('path')
    .datum(FitnessData)
    .attr('transform', "translate(30,30)")
    .attr('fill', "none")
    .attr('stroke', "red")
    .attr('stroke-width', 1.5)
    .attr('d', d3.line()
      .x(d=>x(d.date))
      .y(d=>y(d.squats))
    )
  svg.append('path')
    .datum(FitnessData)
    .attr('transform', "translate(30,30)")
    .attr('fill', "none")
    .attr('stroke', "blue")
    .attr('stroke-width', 1.5)
    .attr('d', d3.line()
      .x(d=>x(d.date))
      .y(d=>y(d.crunches))
    )
  svg.append('path')
    .datum(FitnessData)
    .attr('transform', "translate(30,30)")
    .attr('fill', "none")
    .attr('stroke', "green")
    .attr('stroke-width', 1.5)
    .attr('d', d3.line()
      .x(d=>x(d.date))
      .y(d=>y(d.pushups))
    )

  svg.exit().remove()
}

const D3Foo = (props) => {
   const container = useRef(null)

   useEffect(() => {
      if (container.current) {
         d3render(container)
      }
   }, [container.current])

   return (
      <Card>
         <svg
          ref={container}
          width={`100%`}
          height={`50vh`}
          />
      </Card>
   )
}

export default () => (
  <Layout>
    <D3Foo />
  </Layout>
)
